.customer-vehicles-container {
      .dt-row {
        .dt-col {
          .dt-container {
            min-height: 480px;
            background-color: var(--sdds-grey-50);
            border: 1px solid var(--sdds-grey-400);
            border-radius: 5px;
            overflow-x: auto;
            padding-bottom: var(--sdds-spacing-layout-32);

            .btn-export {
              svg {
                width: 16px;
                height: 16px;
              }

              .sdds-btn-icon {
                display: inline-block;
              }
            }
            table {
              width: 100%;
              ul {
                margin: 0; 
                padding: 0 0 0 17px
              }
            }
            .loader {
              border-radius: 5px;
              background: var(--sdds-white);
              opacity: 0.8;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 99;
              .spinner {
                position: relative;
                z-index: 100;
                top: 25%; 
                flex-direction: column;
                color: var(--sdds-grey-958);
                font-weight: bold;
                .spinner {
                  padding-top: var(--sdds-spacing-layout-16);
                }
              }
            }
          }
        }
      }
}
